import React from 'react';
import './App.css';
import MessageTable from './components/table'

class App extends React.Component {
  render() {
    return (
      <MessageTable />
    );
  }
}

export default App;
